













































































































































































































































































import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import {
  changeUserpermissionSvc,
  updateUserpermissionSvc,
  addUserPermissionByUserTelSvc,
  addUserByTelSvc,
  updateUserpermissionsSvc,
  changeUserpermissionsSvc,
} from '@/services/systemApi';
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

@Component({
  components: {},
})
export default class UserDetail extends Vue {
  $dayjs: any;
  reportres = false;
  modal2 = false;
  modal3 = false;
  modal4 = false;
  modal5 = false;
  modal5Text = '您还没有输入用户名称!';
  modal6 = false;
  showDetail = false;
  indeterminate = true;
  checkAll = false;
  uservalue = '';
  permission: any = [];
  model1 = '';
  permissionList = [
    {
      name: '可以申请客流来源区域',
      value: 'aoi_region_status',
    },
    {
      name: '可以申请竞品',
      value: 'competitive_products',
    },
    {
      name: '精准营销',
      value: 'lbs_dmp',
    },
  ];
  dataTable = [
    {
      name: '客流统计',
      value: 'data_report_statistics',
    },
    {
      name: '客流分析',
      value: 'data_report_analysis',
    },
    {
      name: '客流对比',
      value: 'data_report_comparison',
    },
  ];
  checkAllGroup: any = [];
  // 商圈覆盖
  indeterminate2 = true;
  checkAll2 = false;
  businessGroup = [
    {
      name: '客流来源',
      type: '(旧)',
      value: 'passenger_source_sort',
    },
    {
      name: '商圈客群',
      type: '(旧)',
      value: 'bussiness_analysis',
    },
    {
      name: '本市覆盖',
      value: 'city',
    },
    {
      name: '核心商圈',
      value: 'business_circle_cores',
    },
    {
      name: '市场渗透率',
      value: 'market_permeate',
    },
    {
      name: '客流洼地',
      type: '(旧)',
      value: 'passenger_depressions',
    },
    {
      name: '人数分布',
      type: '(旧)',
      value: 'population_distribution',
    },
    {
      name: '消费水平',
      type: '(旧)',
      value: 'purchasing_power',
    },
    {
      name: '客群分布',
      value: 'group_distribution',
    },
  ];
  checkAllGroup2: any = [];
  // 客流来源
  indeterminate3 = true;
  checkAll3 = false;
  origin = [
    {
      name: '客流来源排名',
      type: '(旧)',
      value: 'visiting_community_rank',
    },
    {
      name: '来源地人口排',
      type: '(旧)',
      value: 'community_crowd_rank',
    },
    {
      name: '客流洼地排名',
      type: '(旧)',
      value: 'depression_rank',
    },
    {
      name: '客源地排行',
      value: 'tourist',
    },
    {
      name: '洼地排名',
      value: 'low_passenger_flow',
    },
    {
      name: ' TOP客源地分析',
      value: 'touristTop',
    },
  ];
  checkAllGroup3: any = [];
  // 竞争环境
  indeterminate4 = true;
  checkAll4 = false;
  environment = [
    {
      name: '商圈影响力',
      value: 'bussiness_effect',
    },
    {
      name: '商场人气热度',
      value: 'bussiness_popularity',
    },
    {
      name: '男性客群热度',
      value: 'man_customer_heat',
    },
    {
      name: '女性客群热度',
      value: 'men_customer_heat',
    },
    {
      name: '各年龄段客群热度',
      value: 'all_age_customer_heat',
    },
    {
      name: '各族群客群热度',
      value: 'all_ethnic_groups_customer_heat',
    },
    {
      name: '不同消费属性客群',
      value: 'different_consumption_groups',
    },
  ];
  checkAllGroup4: any = [];
  // 客流画像
  indeterminate5 = true;
  checkAll5 = false;
  portrait = [
    {
      name: '自然属性',
      value: 'natural_quality',
    },
    {
      name: '财富属性',
      value: 'property_of_wealth',
    },
    {
      name: '到访偏好',
      value: 'visit_preference',
    },
  ];
  checkAllGroup5: any = [];
  // 竞争烈度
  indeterminate6 = true;
  checkAll6 = false;
  compete = [
    {
      name: '竞争区域分析',
      value: 'competition_intensity',
    },
    {
      name: '竞争客流分析',
      value: 'competition_intensity_passenger',
    },
  ];
  checkAllGroup6: any = [];
  // 统计报告
  indeterminate7 = true;
  checkAll7 = false;
  report = [
    {
      name: '竞争区域分析',
      value: 'competition_intensity',
    },
    {
      name: '竞争客流分析',
      value: 'competition_intensity_passenger',
    },
  ];
  checkAllGroup7: any = [];
  columns4 = [
    {
      type: 'selection',
      width: 60,
      align: 'center',
    },
    {
      title: 'MALL-ID',
      key: 'id',
    },
    {
      title: '项目名称',
      key: 'projectName',
    },
    {
      title: '省份',
      key: 'province',
    },
    {
      title: '城市',
      key: 'city',
    },
    {
      title: '区县',
      key: 'district',
    },
  ];
  user = '';
  failTips = '';
  tableSelect: any = [];
  returnPage = false;
  @Prop({
    type: Number,
    required: false,
    default: 1,
  })
  currentPage!: 0;
  @Prop({
    type: String,
    required: false,
    default: '',
  })
  pageName!: string;
  @Prop({
    type: String,
    required: false,
    default: '',
  })
  serviceName!: string;
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  userManage!: false;
  @Prop({
    type: Number,
    required: false,
    default: 0,
  })
  type!: 0;
  @Prop({
    type: Object,
    required: false,
    default: {},
  })
  functionPermission!: '';
  @Prop({
    type: String,
    required: false,
    default: '',
  })
  phone!: '';
  @Prop({
    type: String,
    required: false,
    default: '',
  })
  en_tel!: '';
  @Prop({
    type: Array,
    required: false,
    default: [],
  })
  data1!: [];
  @Prop({
    type: Boolean,
    required: false,
    default: true,
  })
  loading!: boolean;
  @Prop({
    type: String,
    required: false,
    default: '',
  })
  value!: string;
  @Prop({
    type: String,
    required: false,
    default: 'normal',
  })
  user1!: string;
  @Prop({
    type: Number,
    required: false,
    default: 0,
  })
  numOfProject!: 0;
  @Prop({
    type: Array,
    required: false,
    default: [],
  })
  server!: [];
  @Watch('model1', { immediate: false, deep: true })
  model1Change(a: any, b: any): void {
    if (b) {
      this.$emit('tableDataChange', this.model1);
    }
  }
  @Watch('data1', { immediate: false, deep: true })
  data1Change(a: any, b: any): void {
    if (a.length && b.length) {
      this.tableSelect = [];

      if (this.data1 && (this.data1 as any).length) {
        this.data1.forEach((i: any) => {
          if (i._checked) {
            this.tableSelect.push(i.id);
          }
        });
      }
    }
  }
  @Watch('server', { immediate: false, deep: true })
  server1Change(): void {
    if ((this.server as any).length && this.serviceName) {
      (this.server as any).forEach((i: any) => {
        if (i.service_name === this.serviceName) {
          this.model1 = i.en_key;
        }
      });
    } else {
      this.model1 = (this.server as any)[0]
        ? (this.server as any)[0].en_key
        : '';
    }
  }
  @Watch('functionPermission', { immediate: false, deep: true })
  functionPermissionChange(): void {
    // 数据报表
    if (this.pageName === 'operateUser') {
      this.checkAllGroup = [];
      this.dataTable.forEach((i: any) => {
        if (this.functionPermission[i.value] === '1') {
          this.checkAllGroup.push(i.value);
        }
      });
      // 商圈覆盖
      this.checkAllGroup2 = [];
      this.businessGroup.forEach((i: any) => {
        if (this.functionPermission[i.value] === '1') {
          this.checkAllGroup2.push(i.value);
        }
      });
      // 客流来源
      this.checkAllGroup3 = [];
      this.origin.forEach((i: any) => {
        if (this.functionPermission[i.value] === '1') {
          this.checkAllGroup3.push(i.value);
        }
      });
      // 竞争环境
      this.checkAllGroup4 = [];
      this.environment.forEach((i: any) => {
        if (this.functionPermission[i.value] === '1') {
          this.checkAllGroup4.push(i.value);
        }
      });
      // 客流画像
      this.checkAllGroup5 = [];
      this.portrait.forEach((i: any) => {
        if (this.functionPermission[i.value] === '1') {
          this.checkAllGroup5.push(i.value);
        }
      });
      // 竞争烈度
      this.checkAllGroup6 = [];
      this.compete.forEach((i: any) => {
        if (this.functionPermission[i.value] === '1') {
          this.checkAllGroup6.push(i.value);
        }
      });
      // 统计报告
      this.reportres = (this.functionPermission as any).data_report_presentation === '1';
      // 系统权限
      this.permission = [];
      this.permissionList.forEach((i: any) => {
        if (this.functionPermission[i.value] === '1') {
          this.permission.push(i.value);
        }
      });
    }
  }
  addService(): void {
    this.showDetail = true;
  }
  submitBotton(): void {
    this.modal3 = true;
  }
  cancel3(): void {
    this.modal4 = true;
  }
  modalShow(): void {
    this.modal4 = false;
    this.modal6 = false;
    this.modal5 = false;
    if (this.failTips === '配置用户权限成功') {
      this.$emit(
        'returnHomePage',
        false,
        this.userManage ? this.currentPage : null,
      );
    }
  }
  IsShellAttack(input: string) {
    const reg = /[$<>/;!":%}=?%.`]/;
    if (input.match(reg)) {
      return true;
    }
    return false;
  }
  async ok3(): Promise<void> {
    if (this.uservalue) {
      if (this.IsShellAttack(this.uservalue)) {
        this.modal5Text = '用户名称中不能包含特殊字符！';
        this.modal5 = true;
        return;
      }
      const arr = [
        ...this.checkAllGroup,
        ...this.checkAllGroup2,
        ...this.checkAllGroup3,
        ...this.checkAllGroup4,
        ...this.checkAllGroup5,
        ...this.checkAllGroup6,
        ...this.permission,
      ];
      const permissionParams: any = {
        tourist: '0',
        city: '0',
        data_report_statistics: '0',
        data_report_analysis: '0',
        data_report_comparison: '0',
        data_report_presentation: this.reportres ? '1' : '0',
        passenger_source_sort: '0',
        bussiness_analysis: '0',
        business_circle_cores: '0',
        market_permeate: '0',
        passenger_depressions: '0',
        population_distribution: '0',
        purchasing_power: '0',
        group_distribution: '0',
        visiting_community_rank: '0',
        community_crowd_rank: '0',
        depression_rank: '0',
        low_passenger_flow: '0',
        bussiness_effect: '0',
        bussiness_popularity: '0',
        man_customer_heat: '0',
        men_customer_heat: '0',
        all_age_customer_heat: '0',
        all_ethnic_groups_customer_heat: '0',
        different_consumption_groups: '0',
        natural_quality: '0',
        property_of_wealth: '0',
        visit_preference: '0',
        competition_intensity: '0',
        competition_intensity_passenger: '0',
        aoi_region_status: '0',
        competitive_products: '0',
      };
      if (this.userManage) {
        permissionParams.lbs_dmp = '0';
      }
      if (arr.length) {
        arr.forEach((i: string) => {
          (permissionParams as any)[i] = '1';
        });
      }
      let role: any;
      if (this.user === 'normal') {
        role = '3';
      } else if (this.user === 'boss') {
        role = '2';
      } else {
        role = '1';
      }
      if (this.userManage) {
        // 设置｜更新权限
        if (this.type) {
          const res = await addUserByTelSvc({
            tel: this.phone,
            en_tel: this.en_tel,
            user: this.uservalue,
            privilege: parseInt(role, 10),
            en_key: this.model1,
          });
          this.returnPage = !res.status;
        } else {
          // 用户权限更新接口
          const res = await changeUserpermissionsSvc({
            tel: this.phone,
            en_tel: this.en_tel,
            user: this.uservalue,
            privilege: parseInt(role, 10),
            en_key: this.model1,
          });
          this.returnPage = !res.status;
        }
      } else {
        // 设置｜更新权限
        if (this.type) {
          const res = await addUserPermissionByUserTelSvc({
            tel: this.phone,
            en_tel: this.en_tel,
            user: this.uservalue,
            privilege: parseInt(role, 10),
          });
          this.returnPage = !res.status;
        } else {
          // 用户权限更新接口
          const res = await changeUserpermissionSvc({
            en_tel: this.en_tel,
            tel: this.phone,
            user: this.uservalue,
            privilege: parseInt(role, 10),
          });
          this.returnPage = !res.status;
        }
      }
      // 用户绑定
      let userpermission;
      if (this.userManage) {
        userpermission = await updateUserpermissionsSvc({
          en_tel: this.en_tel,
          tel: this.phone,
          user_mid_ary: this.tableSelect.length ? this.tableSelect : [0],
          page_url_obj: permissionParams,
        });
      } else {
        userpermission = await updateUserpermissionSvc({
          en_tel: this.en_tel,
          tel: this.phone,
          user_mid_ary: this.tableSelect.length ? this.tableSelect : [0],
          page_url_obj: permissionParams,
        });
      }
      this.returnPage = !userpermission.status;
      this.failTips = userpermission.info;
      this.modal6 = true;
    } else {
      this.modal5Text = '您还没有输入用户名称！';
      this.modal5 = true;
    }
  }
  ok6(): void {
    if (this.returnPage) {
      this.$emit(
        'returnHomePage',
        false,
        this.userManage ? this.currentPage : null,
      );
    }
  }
  ok(): void {
    this.$emit(
      'returnHomePage',
      false,
      this.userManage ? this.currentPage : null,
    );
  }
  ok2(): void {
    this.modal2 = false;
    this.$emit(
      'returnHomePage',
      false,
      this.userManage ? this.currentPage : null,
    );
  }
  cancel2(): void {
    this.modal2 = false;
  }
  cancelBotton(): void {
    this.modal2 = true;
  }
  tableClick(row: any): void {
    this.tableSelect = [];
    if ((row as any) && (row as any).length) {
      row.forEach((i: any) => {
        this.tableSelect.push(i.id);
      });
    }
  }
  handleCheckAll(): void {
    if (this.indeterminate) {
      this.checkAll = false;
    } else {
      this.checkAll = !this.checkAll;
    }
    this.indeterminate = false;

    if (this.checkAll) {
      this.checkAllGroup = this.dataTable.map((i: any) => (i as any).value);
    } else {
      this.checkAllGroup = [];
    }
  }
  checkAllGroupChange(data: any): void {
    if (
      data.length === this.dataTable.map((i: any) => (i as any).value).length
    ) {
      this.indeterminate = false;
      this.checkAll = true;
    } else if (data.length > 0) {
      this.indeterminate = true;
      this.checkAll = false;
    } else {
      this.indeterminate = false;
      this.checkAll = false;
    }
  }
  // 商圈覆盖
  handleCheckAll2(): void {
    if (this.indeterminate2) {
      this.checkAll2 = false;
    } else {
      this.checkAll2 = !this.checkAll2;
    }
    this.indeterminate2 = false;

    if (this.checkAll2) {
      this.checkAllGroup2 = this.businessGroup.map((i: any) => (i as any).value);
    } else {
      this.checkAllGroup2 = [];
    }
  }
  checkAllGroupChange2(data: any): void {
    if (
      data.length
      === this.businessGroup.map((i: any) => (i as any).value).length
    ) {
      this.indeterminate2 = false;
      this.checkAll2 = true;
    } else if (data.length > 0) {
      this.indeterminate2 = true;
      this.checkAll2 = false;
    } else {
      this.indeterminate2 = false;
      this.checkAll2 = false;
    }
  }
  // 客流来源
  handleCheckAll3(): void {
    if (this.indeterminate3) {
      this.checkAll3 = false;
    } else {
      this.checkAll3 = !this.checkAll3;
    }
    this.indeterminate3 = false;

    if (this.checkAll3) {
      this.checkAllGroup3 = this.origin.map((i: any) => (i as any).value);
    } else {
      this.checkAllGroup3 = [];
    }
  }
  checkAllGroupChange3(data: any): void {
    if (data.length === this.origin.map((i: any) => (i as any).value).length) {
      this.indeterminate3 = false;
      this.checkAll3 = true;
    } else if (data.length > 0) {
      this.indeterminate3 = true;
      this.checkAll3 = false;
    } else {
      this.indeterminate3 = false;
      this.checkAll3 = false;
    }
  }
  mounted(): void {
    this.user = this.user1;
    this.uservalue = this.value;
    // this.tableSelect = [];
    // if (this.data1 && (this.data1 as any).length) {
    //   this.data1.forEach((i: any) => {
    //     if (i._checked) {
    //       this.tableSelect.push(i.id);
    //     }
    //   });
    // }
    if (this.pageName === 'appendUser') {
      this.checkAllGroup = this.dataTable.map((i: any) => (i as any).value);
      this.checkAllGroup2 = this.businessGroup.map((i: any) => (i as any).value);
      this.checkAllGroup3 = this.origin.map((i: any) => (i as any).value);
      this.checkAllGroup4 = this.environment.map((i: any) => (i as any).value);
      this.checkAllGroup5 = this.portrait.map((i: any) => (i as any).value);
      this.checkAllGroup6 = this.compete.map((i: any) => (i as any).value);
      this.permission = this.userManage
        ? this.permissionList.map((i: any) => (i as any).value)
        : this.permissionList.map((i: any) => (i as any).value).slice(0, 2);
      this.reportres = true;
    }
  }
  // 竞争环境
  handleCheckAll4(): void {
    if (this.indeterminate4) {
      this.checkAll4 = false;
    } else {
      this.checkAll4 = !this.checkAll4;
    }
    this.indeterminate4 = false;

    if (this.checkAll4) {
      this.checkAllGroup4 = this.environment.map((i: any) => (i as any).value);
    } else {
      this.checkAllGroup4 = [];
    }
  }
  checkAllGroupChange4(data: any): void {
    if (
      data.length === this.environment.map((i: any) => (i as any).value).length
    ) {
      this.indeterminate4 = false;
      this.checkAll4 = true;
    } else if (data.length > 0) {
      this.indeterminate4 = true;
      this.checkAll4 = false;
    } else {
      this.indeterminate4 = false;
      this.checkAll4 = false;
    }
  }
  // 客流画像
  handleCheckAll5(): void {
    if (this.indeterminate5) {
      this.checkAll5 = false;
    } else {
      this.checkAll5 = !this.checkAll5;
    }
    this.indeterminate5 = false;

    if (this.checkAll5) {
      this.checkAllGroup5 = this.portrait.map((i: any) => (i as any).value);
    } else {
      this.checkAllGroup5 = [];
    }
  }
  checkAllGroupChange5(data: any): void {
    if (
      data.length === this.portrait.map((i: any) => (i as any).value).length
    ) {
      this.indeterminate5 = false;
      this.checkAll5 = true;
    } else if (data.length > 0) {
      this.indeterminate5 = true;
      this.checkAll5 = false;
    } else {
      this.indeterminate5 = false;
      this.checkAll5 = false;
    }
  }
  // 竞争烈度
  handleCheckAll6(): void {
    if (this.indeterminate6) {
      this.checkAll6 = false;
    } else {
      this.checkAll6 = !this.checkAll6;
    }
    this.indeterminate6 = false;

    if (this.checkAll6) {
      this.checkAllGroup6 = this.compete.map((i: any) => (i as any).value);
    } else {
      this.checkAllGroup6 = [];
    }
  }
  checkAllGroupChange6(data: any): void {
    if (data.length === this.compete.map((i: any) => (i as any).value).length) {
      this.indeterminate6 = false;
      this.checkAll6 = true;
    } else if (data.length > 0) {
      this.indeterminate6 = true;
      this.checkAll6 = false;
    } else {
      this.indeterminate6 = false;
      this.checkAll6 = false;
    }
  }
  // 统计报告
  handleCheckAll7(): void {
    if (this.indeterminate7) {
      this.checkAll7 = false;
    } else {
      this.checkAll7 = !this.checkAll7;
    }
    this.indeterminate7 = false;
  }
}
